import React from 'react';
import { Container, Typography } from "@mui/material"
import { Link, useParams } from 'react-router-dom'
import { Card, CardMedia, CardContent, CardActionArea } from "@mui/material"
import { List, ListItem, ListItemText, ListItemButton } from "@mui/material"
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import { useSelector, AppState } from "../store"
import { NFTKey, token_obs } from '../utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchTokenDialog from '../components/SearchTokenDialog';
import { Storage } from '../utils/MyStorage';

function TokenView() {
  const params = useParams()

  const showSearch = useSelector<AppState, boolean>((state) => state.ui.showSearch)

  const [name, setName] = React.useState<string>("")
  const [description, setDescription] = React.useState<string>("")
  const [image, setImage] = React.useState<string>("")
  const [URIType, setURIType] = React.useState<string>("")
  const [ImageType, setImageType] = React.useState<string>("")
  const [owner, setOwner] = React.useState<string>("")

  React.useEffect(() => {
    token_obs(params.chain as string, params.contract as string, params.token as string).subscribe({
      next: next,
      //   error: function (error) {
      //     dispatch(actionLoading(false))
      //     dispatch(actionTokenId(oldId))

      //     let errMsg = etherError(error)
      //     dispatch(actionErrMsg(errMsg.length > 0 ? errMsg : error.message))
      //     console.log(error.message)
      //     setError(true)
      //   },
      //  complete: () => setError(false)
    })
  }, [params])

  const next = (value: { [key: string]: any }) => {

    console.log(value)
    switch (value.name) {
      case NFTKey.AssetName:
        setName(value.value)
        break
      case NFTKey.AssetDescription:
        setDescription(value.value)
        break
      case NFTKey.Image:
        setImage(value.value)
        break
      case NFTKey.URIType:
        setURIType(value.value)
        break
      case NFTKey.ImageType:
        setImageType(value.value)
        break
      case NFTKey.Owner:
        setOwner(value.value)
        break
        case NFTKey.Cache:
          let v = value.value
          Storage.set(v.key, v.value, v.expires)
          break
    }
  }

  return (
    <Container>
      <Card>
        <CardActionArea>
          <CardMedia
            component="img"
            // height="140"
            image={image}
            alt={name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {name ? name : params.token}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      {owner && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Properties</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem disablePadding>
                <ListItemButton component={Link} to={`/${params.chain}/${params.contract}`}>
                  <ListItemText primary={`Contract Address`} secondary={<Typography variant="subtitle2" noWrap={true}>{params.contract}</Typography>} />
                </ListItemButton>
              </ListItem>
              <ListItem secondaryAction={params.token} disablePadding>
                <ListItemButton>
                  <ListItemText primary={`Token ID`} />
                </ListItemButton>
              </ListItem>
              <ListItem secondaryAction={URIType} disablePadding>
                <ListItemButton>
                  <ListItemText primary={`Asset Metadata`} />
                </ListItemButton>
              </ListItem>
              <ListItem secondaryAction={ImageType} disablePadding>
                <ListItemButton>
                  <ListItemText primary={`Image`} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={"a"} href={`https://etherscan.io/address/${owner}`}>
                  <ListItemText primary={`Owner`} secondary={<Typography variant="subtitle2" noWrap={true}>{owner}</Typography>} />
                </ListItemButton>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      )}

      <SearchTokenDialog open={showSearch} chain={params.chain} contract={params.contract} />
    </Container>
  )
}

export default TokenView