import React from 'react';
import { Link } from 'react-router-dom'
import { Container } from "@mui/material"
import { List, ListSubheader, ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar } from "@mui/material"
import { Providers } from "../utils"

function HomeView() {
  return (
    <Container sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Support Providers
          </ListSubheader>
        }
      >
        {Providers.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton component={Link} to={`/${item.key}`}>
              <ListItemAvatar>
                <Avatar alt={item.name} src={item.icon} />
              </ListItemAvatar>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Container>
  )
}

export default HomeView