import { createReducer } from '@reduxjs/toolkit'
import { actionSearch } from '../actions'

interface State {
    showSearch: boolean
}

const initialState: State = {
    showSearch: false
}

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(actionSearch, (state, action) => {
            state.showSearch = action.payload
        })
})

export default reducer;