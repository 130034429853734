import React from 'react';
import { Link, useParams } from 'react-router-dom'
import { Skeleton, ImageListItem, ImageListItemBar, IconButton } from "@mui/material"
import InfoIcon from '@mui/icons-material/Info';
import { NFTKey, token_index_obs } from '../utils'
import { Storage } from '../utils/MyStorage';

function NFTGridCell(props: any) {
  const params = useParams()

  const [name, setName] = React.useState<string>("")
  const [description, setDescription] = React.useState<string>("")
  const [image, setImage] = React.useState<string>("")
  const [tokenId, setTokenId] = React.useState<string>("")

  React.useEffect(() => {
    token_index_obs(params.chain as string, params.contract as string, props.index).subscribe({
      next: next,
      //   error: function (error) {
      //     dispatch(actionLoading(false))
      //     dispatch(actionTokenId(oldId))

      //     let errMsg = etherError(error)
      //     dispatch(actionErrMsg(errMsg.length > 0 ? errMsg : error.message))
      //     console.log(error.message)
      //     setError(true)
      //   },
      //  complete: () => setError(false)
    })
  }, [params, props])

  const next = (value: { [key: string]: any }) => {

    console.log(value)

    switch (value.name) {
      case NFTKey.AssetName:
        setName(value.value)
        break
      case NFTKey.AssetDescription:
        setDescription(value.value)
        break
      case NFTKey.Image:
        setImage(value.value)
        break
      case NFTKey.TokenId:
        setTokenId(value.value)
        break
      case NFTKey.Cache:
        let v = value.value
        Storage.set(v.key, v.value, v.expires)
    }
  }

  return (
    <ImageListItem>
      (image ? (
      <img
        src={image}
        // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
        alt={name}
        loading="lazy"
      />
      <ImageListItemBar
        title={name ? name : tokenId}
        subtitle={description}
        actionIcon={
          <IconButton
            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
            aria-label={`info about ${name}`}
            component={Link}
            to={`/${params.chain}/${params.contract}/${tokenId}`}
          >
            <InfoIcon />
          </IconButton>
        }
      />
      ):(
      <Skeleton variant="rectangular" height="100" />
      )
    </ImageListItem>
  );
}

export default NFTGridCell