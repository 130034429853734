import { Md5 } from "ts-md5";

export class Storage {

    static get(obj: object) {
        let key = Md5.hashStr(JSON.stringify(obj));
        return this.getItem(key)
    }

    static set(obj: object, value: any, expires:number = 0) {
        let key = Md5.hashStr(JSON.stringify(obj));
        this.setItem(key, value, expires)
    }

    static getItem(key: string) {
    
        let item = localStorage.getItem(key);
        if (item === null) {
            return null;
        }

        let data = JSON.parse(item);
        if (data.e !== null && data.e <= new Date().getTime()) {
            localStorage.removeItem(key);
            return null;
        }

        return data.v;
    }

    static setItem(key: string, value: any, expires:number = 0) {
        if (expires > 0) {
            localStorage.setItem(key, JSON.stringify({
                "v": value,
                "e": expires + new Date().getTime()
            }));
        } else {
            localStorage.setItem(key, JSON.stringify({
                "v": value
            }));
        }
    }
 
    static removeItem(key: string) {
        localStorage.removeItem(key);
    }

    static clear() {
        localStorage.clear();
    }
}
