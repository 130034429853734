import React from 'react';
import { Button, Container } from "@mui/material"
import { Link } from 'react-router-dom'

function NoMatchView() {

  return (
    <Container>
      <Button component={Link} to="/" variant="outlined" size="large">Go Home</Button>
    </Container>
  )
}

export default NoMatchView