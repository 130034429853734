import React from 'react';
import { Outlet, Link, useParams } from 'react-router-dom'
import { CssBaseline, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, AppDispatch, actionSearch} from "./store"

function App() {

  const params = useParams()
  const dispatch = useDispatch<AppDispatch>()

  const handleSearch = () => {
    dispatch(actionSearch(true))
  }

  return (
    <React.Fragment>
    <CssBaseline />
    <AppBar position="sticky">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            component={Link}
            to="/"
          >
            <ExploreIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            NFT Explorer
          </Typography>
          {params.chain && (<IconButton
                size="large"
                onClick={handleSearch}
                color="inherit"
              >
                <SearchIcon />
              </IconButton>)}
        </Toolbar>
      </AppBar>
    <Outlet />
  </React.Fragment>
  );
}

export default App;
