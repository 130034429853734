import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import {Button, InputAdornment, TextField} from '@mui/material';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, AppDispatch, actionSearch} from "../store"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SearchTokenDialog(props: any) {
  const [tokenId, setTokenId] = React.useState<string>("")
  const dispatch = useDispatch<AppDispatch>()
  const nav = useNavigate()

  const handleClickOpen = () => {
    dispatch(actionSearch(false))
    nav(`/${props.chain}/${props.contract}/${tokenId}`)
  };

  const handleClose = () => {
    dispatch(actionSearch(false))
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTokenId(event.target.value)
  };

  return (
      <Dialog open={props.open} onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      >
        <DialogTitle>Search NFT</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter Token ID for current contract.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Token ID"
            fullWidth
            variant="standard"
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon sx={{ mr: 0.3 }} /></InputAdornment>,
            }}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClickOpen}>Access</Button>
        </DialogActions>
      </Dialog>
  );
}