import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { HashRouter, Routes, Route  } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import store from './store'
import App from './App';
import HomeView from './views/HomeView'
import ChainView from './views/ChainView'
import ContractView from './views/ContractView'
import TokenView from './views/TokenView'
import NoMatchView from './views/NoMatchView'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <Provider store={store}>
      <HashRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<HomeView />} />
          <Route path="/:chain" element={<ChainView />} />
          <Route path="/:chain/:contract" element={<ContractView />} />
            <Route path="/:chain/:contract/:token" element={<TokenView />} />
          </Route>
          <Route path="*" element={<NoMatchView />} />
      </Routes>
    </HashRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
