import { ethers } from 'ethers'
import { fromFetch } from 'rxjs/fetch';
import { from, of, map, switchMap, Observable, mergeAll, mergeMap } from "rxjs"
import { IERC165__factory, IERC165, IERC721__factory, IERC721 } from '../cache/typings/';
import { IERC721Enumerable__factory, IERC721Enumerable, IERC721Metadata__factory, IERC721Metadata } from '../cache/typings/';
import { BscscanProvider } from "@ethers-ancillary/bsc";
import { Storage } from './MyStorage';

export enum ProviderKey {
    eth = "eth",
    infura = "infura",
    alchemy = "alchemy",
    bsc = "bsc"
}

export const Providers = [
    {
        "name": "Ethereum",
        "key": ProviderKey.eth,
        "icon": require('../Icons/ethereum-eth-logo.png')
    },
    {
        "name": "Ethereum (Infura)",
        "key": ProviderKey.infura,
        "icon": require('../Icons/infura-logo.png')
    },
    {
        "name": "Ethereum (Alchemy)",
        "key": ProviderKey.alchemy,
        "icon": require('../Icons/alchemy-logo.png')
    },
    {
        "name": "Binacne",
        "key": ProviderKey.bsc,
        "icon": require('../Icons/bnb-bnb-logo.png')
    },
]

export const RecentContracts = [
    {
        "type": "NFTich",
        "key": ProviderKey.eth,
        "address": "0xcF8CF01cBA153497b0AAc34C6ce3B52017fBAaFc"
    },
    {
        "type": "STRBRY",
        "key": ProviderKey.eth,
        "address": "0xd596ffcd875e30e91608b1e4029882466e1b7ee4"
    },
    {
        "type": "COOL",
        "key": ProviderKey.eth,
        "address": "0x1a92f7381b9f03921564a437210bb9396471050c"
    },
    {
        "type": "SPUNK",
        "key": ProviderKey.eth,
        "address": "0x9a604220d37b69c09effccd2e8475740773e3daf"
    },
    {
        "type": "BNFT",
        "key": ProviderKey.bsc,
        "address": "0xdc18ad357d7a25b57d9c3fd5426c9b648aa911ce"
    },
    {
        "type": "HPA",
        "key": ProviderKey.bsc,
        "address": "0x3d24C45565834377b59fCeAA6864D6C25144aD6c"
    },
    {
        "type": "OAT",
        "key": ProviderKey.bsc,
        "address": "0xADc466855ebe8d1402C5F7e6706Fccc3AEdB44a0"
    },
]

export function providerIcon(key: string): any {
    for (let i = 0; i < Providers.length; i++) {
        let item = Providers[i]
        if (item.key === key) {
            return item.icon
        }
    }
}

function ethersProvider(chain: string): ethers.providers.Provider | ethers.Signer {

    switch (chain) {
        case ProviderKey.infura:
            return new ethers.providers.InfuraProvider()
        case ProviderKey.alchemy:
            return new ethers.providers.AlchemyProvider(undefined, "-Po-ULUbhOvyTCNCVJzS06aEItJIkcS6")
        case ProviderKey.bsc:
            return new BscscanProvider()
        case ProviderKey.eth:
        default:
            return ethers.providers.getDefaultProvider()
    }
}

function ERC165(chain: string, addr: string): IERC165 {
    return IERC165__factory.connect(addr, ethersProvider(chain))
}

function ERC721(chain: string, addr: string): IERC721 {
    return IERC721__factory.connect(addr, ethersProvider(chain))
}

function ERC721Metadata(chain: string, addr: string): IERC721Metadata {
    return IERC721Metadata__factory.connect(addr, ethersProvider(chain))
}

function ERC721Enumerable(chain: string, addr: string): IERC721Enumerable {
    return IERC721Enumerable__factory.connect(addr, ethersProvider(chain))
}

export enum IID {
    ERC721 = 0x80ac58cd,
    ERC721Metadata = 0x5b5e139f,
    ERC721Enumerable = 0x780e9d63,
    ERC1155 = 0xd9b67a26
}

export enum NFTKey {
    Cache = "Cache",
    Name = "Name",
    Symbol = "Symbol",
    TotalSupply = "TotalSupply",
    TokenId = "TokenId",
    TokenURI = "TokenURI",
    Owner = "Owner",
    URIType = "URIType",
    ImageType = "ImageType",
    AssetName = "AssetName",
    AssetDescription = "AssetDescription",
    Image = "Image"
}

enum CacheLevel {
    low = 30 * 1000,
    middle = 5 * 60 * 1000,
    high = 30 * 60 * 1000
}

function supportsInterface_obs(chain: string, addr: string, iid: IID, obs: Observable<any> = of()): Observable<any> {

    let key = { chain: chain, addr: addr, iid: iid }
    let value = Storage.get(key)
    if (value != null) {
        return of(obs, of({ name: iid.toString(), value: value, cache: true })).pipe(mergeAll())
    }

    return from(ERC165(chain, addr).supportsInterface(ethers.utils.arrayify(iid))).pipe(
        mergeMap(x => x ?
            of(obs,
                of({ name: iid.toString(), value: x }),
                of({
                    name: NFTKey.Cache, value: {
                        key: key, value: x, expires: CacheLevel.high
                    }
                })
            ).pipe(mergeAll())
            : of({ name: iid.toString(), value: x }))
    )
}

function ERC721_ownerOf_obs(chain: string, addr: string, tokenId: string): Observable<any> {

    let key = { chain: chain, addr: addr, tokenId: tokenId, key: NFTKey.Owner }
    let value = Storage.get(key)
    if (value != null) {
        return of({ name: NFTKey.Owner, value: value, cache: true })
    }

    return from(ERC721(chain, addr).ownerOf(tokenId)).pipe(
        mergeMap(x => of(
            of({ name: NFTKey.Owner, value: x }),
            of({
                name: NFTKey.Cache, value: {
                    key: key, value: x, expires: CacheLevel.low
                }
            })
        ).pipe(mergeAll())
    ))
}

function ERC721Metadata_name_obs(chain: string, addr: string): Observable<any> {
    let key = { chain: chain, addr: addr, key: NFTKey.Name }
    let value = Storage.get(key)
    if (value != null) {
        return of({ name: NFTKey.Name, value: value, cache: true })
    }

    return from(ERC721Metadata(chain, addr).name()).pipe(
        mergeMap(x => of(
            of({ name: NFTKey.Name, value: x }),
            of({
                name: NFTKey.Cache, value: {
                    key: key, value: x, expires: CacheLevel.high
                }
            })
        ).pipe(mergeAll())),
    )
}

function ERC721Metadata_symbol_obs(chain: string, addr: string): Observable<any> {
    let key = { chain: chain, addr: addr, key: NFTKey.Symbol }
    let value = Storage.get(key)
    if (value != null) {
        return of({ name: NFTKey.Symbol, value: value, cache: true })
    }

    return from(ERC721Metadata(chain, addr).symbol()).pipe(
        mergeMap(x => of(
            of({ name: NFTKey.Symbol, value: x }),
            of({
                name: NFTKey.Cache, value: {
                    key: key, value: x, expires: CacheLevel.high
                }
            })
        ).pipe(mergeAll())),
    )
}

function ERC721Metadata_tokenURI_obs(chain: string, addr: string, tokenId: string, obs: (x: string) => Observable<any>): Observable<any> {

    let key = { chain: chain, addr: addr, tokenId: tokenId, key: NFTKey.TokenURI }
    let value = Storage.get(key)
    if (value != null) {
        return of(
            obs(value),
            of({ name: NFTKey.TokenURI, value: value, cache: true }),
            ).pipe(mergeAll())
    }

    return from(ERC721Metadata(chain, addr).tokenURI(tokenId)).pipe(
        mergeMap(x => of(
            obs(x),
            of({ name: NFTKey.TokenURI, value: x }),
            of({
                name: NFTKey.Cache, value: {
                    key: key, value: x, expires: CacheLevel.middle
                }
            })).pipe(
                mergeAll()
            )))
}


function ERC721Enumerable_totalSupply_obs(chain: string, addr: string): Observable<any> {
    let key = { chain: chain, addr: addr, key: NFTKey.TotalSupply }
    let value = Storage.get(key)
    if (value != null) {
        return of({ name: NFTKey.TotalSupply, value: value, cache: true })
    }

    return from(ERC721Enumerable(chain, addr).totalSupply()).pipe(
        mergeMap(x => of(
            of({ name: NFTKey.TotalSupply, value: x.toString() }),
            of({
                name: NFTKey.Cache, value: {
                    key: key, value: x.toString(), expires: CacheLevel.low
                }
            })
        ).pipe(
            mergeAll()
        )),
    )
}

function ERC721Enumerable_tokenByIndex_obs(chain: string, addr: string, index: ethers.BigNumber, obs: (x: string) => Observable<any>): Observable<any> {

    let key = { chain: chain, addr: addr, index: index.toString(), key: NFTKey.TokenId }
    let value = Storage.get(key)
    if (value != null) {
        return of(
            obs(value),
            of({ name: NFTKey.TokenId, value: value, cache: true }),
        ).pipe(mergeAll())
    }

    return from(ERC721Enumerable(chain, addr).tokenByIndex(index)).pipe(
        mergeMap(x => of(
            obs(x.toString()),
            of({ name: NFTKey.TokenId, value: x.toString() }),
            of({
                name: NFTKey.Cache, value: {
                    key: key, value: x.toString(), expires: CacheLevel.middle
                }
            })).pipe(
                mergeAll()
            )))
}

function AssetMetadataJSONObs(obj: any): Observable<any> {

    if (obj.image.startsWith('ipfs://')) {
        obj.image = 'https://ipfs.io/ipfs/' + obj.image.substring(7)
    }

    return of(
        ({ "name": NFTKey.AssetName, "value": obj.name }),
        ({ "name": NFTKey.AssetDescription, "value": obj.description }),
        ({ "name": NFTKey.Image, "value": obj.image }),
    )
}

function AssetMetadataObs(uri: string): Observable<any> {

    if (uri.startsWith('data:image/')) {
        return of(
            ({ "name": NFTKey.Image, value: uri }),
            ({ "name": NFTKey.URIType, value: "none" }),
            ({ "name": NFTKey.ImageType, value: "data" }),
        )
    }

    if (uri.startsWith('data:application/json;base64,')) {
        // 29 = length of "data:application/json;base64,"
        const json = atob(uri.substring(29));
        // const json = window.Buffer.from(uri.substring(29), "base64").toString();
        const obj = JSON.parse(json);
        console.log("JSON: " + json)
        return of(
            of({ "name": NFTKey.URIType, value: "data" }),
            AssetMetadataJSONObs(obj)
        ).pipe(
            mergeAll()
        )
    }

    if (uri.startsWith('ipfs://')) {
        uri = 'https://ipfs.io/ipfs/' + uri.substring(7)
    }

    return fromFetch(uri).pipe(
        switchMap(response => {
            if (response.ok) {
                // OK return data
                return response.json();
            } else {
                // Server is returning a status requiring the client to try something else.
                return of({ error: true, message: `Error ${response.status}` });
            }
        }),
        map(x => of(
            of({ "name": NFTKey.URIType, value: uri.split("://")[0] }),
            AssetMetadataJSONObs(x),
        ).pipe(
            mergeAll()
        )),
        mergeAll()
    );
}

export function nft_obs(chain: string, addr: string): Observable<any> {

    return of(
        supportsInterface_obs(chain, addr, IID.ERC721Metadata, 
            of(
                ERC721Metadata_name_obs(chain, addr),
                ERC721Metadata_symbol_obs(chain, addr)
            ).pipe(
                mergeAll()
            )
        ),
        supportsInterface_obs(chain, addr, IID.ERC721Enumerable, ERC721Enumerable_totalSupply_obs(chain, addr)),
        supportsInterface_obs(chain, addr, IID.ERC721),
        supportsInterface_obs(chain, addr, IID.ERC1155)
    ).pipe(
        mergeAll()
    )
}

export function token_obs(chain: string, addr: string, tokenId: string): Observable<any> {

    return of(
        supportsInterface_obs(chain, addr, IID.ERC721Metadata, 
            ERC721Metadata_tokenURI_obs(chain, addr, tokenId, (x: string) => {
                return AssetMetadataObs(x)
            })
        ),
        supportsInterface_obs(chain, addr, IID.ERC721, ERC721_ownerOf_obs(chain, addr, tokenId)),
    ).pipe(
        mergeAll()
    )
}

export function token_index_obs(chain: string, addr: string, index: number): Observable<any> {

    return supportsInterface_obs(chain, addr, IID.ERC721Enumerable, 
        ERC721Enumerable_tokenByIndex_obs(chain, addr, ethers.BigNumber.from(index), (x) => {
            return supportsInterface_obs(chain, addr, IID.ERC721Metadata,             
                ERC721Metadata_tokenURI_obs(chain, addr, x, (x) => {
                    return AssetMetadataObs(x.toString())
                })
            )
        })
    )
}