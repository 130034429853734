import React from 'react';
import { useParams, useLocation, Link } from 'react-router-dom'
import { Container, Typography, Skeleton } from "@mui/material"
import { List, ListItem, IconButton, ListItemText, ListItemButton } from "@mui/material"
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import { NFTKey, IID, nft_obs, providerIcon } from '../utils'
import { useSelector, AppState } from "../store"
import NFTGrid from '../components/NFTGrid'
import SearchTokenDialog from '../components/SearchTokenDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Storage } from '../utils/MyStorage';

function ContractView() {
  const params = useParams()
  const search = useLocation().search;
  const page = new URLSearchParams(search).get('page');
  const count = new URLSearchParams(search).get('count');

  const showSearch = useSelector<AppState, boolean>((state) => state.ui.showSearch)

  const [name, setName] = React.useState<string>("")
  const [symbol, setSymbol] = React.useState<string>("")
  const [totalSupply, setTotalSupply] = React.useState<number>(0)
  const [ERC721, setERC721] = React.useState<string>('-')
  const [ERC721Enumerable, setERC721Enumerable] = React.useState<string>('-')
  const [ERC721Metadata, setERC721Metadata] = React.useState<string>('-')
  const [ERC1155, setERC1155] = React.useState<string>('-')

  React.useEffect(() => {

    nft_obs(params.chain as string, params.contract as string).subscribe({
      next: next,
      //   error: function (error) {
      //     dispatch(actionLoading(false))
      //     dispatch(actionTokenId(oldId))

      //     let errMsg = etherError(error)
      //     dispatch(actionErrMsg(errMsg.length > 0 ? errMsg : error.message))
      //     console.log(error.message)
      //     setError(true)
      //   },
      //  complete: () => setError(false)
    })
  }, [params])

  const next = (value: { [key: string]: any }) => {

    console.log(value)

    switch (value.name) {
      case NFTKey.Name:
        setName(value.value)
        break
      case NFTKey.Symbol:
        setSymbol(value.value)
        break
      case NFTKey.TotalSupply:
        setTotalSupply(value.value)
        break
      case NFTKey.Cache:
        let v = value.value
        Storage.set(v.key, v.value, v.expires)
        break
      case IID.ERC721.toString():
        setERC721(value.value ? 'Yes' : 'No')
        break
      case IID.ERC1155.toString():
        setERC1155(value.value ? 'Yes' : 'No')
        break
      case IID.ERC721Enumerable.toString():
        setERC721Enumerable(value.value ? 'Yes' : 'No')
        break
      case IID.ERC721Metadata.toString():
        setERC721Metadata(value.value ? 'Yes' : 'No')
        break
    }
  }

  const get_page = () => {
    if (page) {
      let p = Number(page)
      return (p <= 0) ? 1 : p
    }

    return 1
  }

  const get_count = () => {
    if (count) {
      let p = Number(count)
      return (p <= 0) ? 1 : p
    }

    return 10
  }


  return (
    <Container sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
      <List>
        <ListItem
          secondaryAction={
            <IconButton edge="end" aria-label="comments" component={Link} to={`/${params.chain}`}>
              <img src={providerIcon(params.chain as string)} alt="" width={48} height={48} />
            </IconButton>
          }
          disablePadding
        >
          <ListItemText primary={
            <Typography gutterBottom variant="h5" component="div">
              {name ? name : <Skeleton width="60%" />}
            </Typography>
          } secondary={
            <Typography variant="body2" color="text.secondary">
              {symbol ? symbol : <Skeleton width="40%" />}
            </Typography>
          } />
        </ListItem>
      </List>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Properties</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem secondaryAction={ERC721} disablePadding>
              <ListItemButton>
                <ListItemText primary={`ERC721`} />
              </ListItemButton>
            </ListItem>
            <ListItem secondaryAction={ERC721Metadata} disablePadding>
              <ListItemButton>
                <ListItemText primary={`ERC721Metadata`} />
              </ListItemButton>
            </ListItem>
            <ListItem secondaryAction={ERC721Enumerable} disablePadding>
              <ListItemButton>
                <ListItemText primary={`ERC721Enumerable`} />
              </ListItemButton>
            </ListItem>
            <ListItem secondaryAction={ERC1155} disablePadding>
              <ListItemButton>
                <ListItemText primary={`ERC1155`} />
              </ListItemButton>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      {totalSupply > 0 &&
        <NFTGrid total={totalSupply} page={get_page()} count={get_count()} />
      }

      <SearchTokenDialog open={showSearch} chain={params.chain} contract={params.contract} />
    </Container>
  )
}

export default ContractView