import React from 'react';
import { Link, useParams } from 'react-router-dom'
import { Stack, PaginationItem } from "@mui/material"
import { ListSubheader, ImageList, ImageListItem, Pagination } from "@mui/material"
import NFTGridCell from './NFTGridCell';

interface NFTGridProps {
  total: number
  page: number
  count: number
}

function NFTGrid(props: NFTGridProps) {
  const params = useParams()

  const start = () => {
    return (props.page - 1) * props.count
  }

  const end = () => {
    let e = props.page * props.count
    return e > props.total ? props.total : e
  }

  return (
    <Stack>
      <ImageList>
        <ImageListItem key="Subheader" cols={2}>
          <ListSubheader component="div">{props.total} total</ListSubheader>
        </ImageListItem>

        {(() => {
          const arr = [];
          for (let i = start(); i < end(); i++) {
            arr.push(
              <NFTGridCell key={i} index={i} />
            );
          }
          return arr;
        })()}

      </ImageList>

      {props.total > 10 && (
        <Pagination count={Math.floor(props.total / props.count)} variant="outlined" shape="rounded"
          size="large"
          defaultPage={props.page}
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={`/${params.chain}/${params.contract}${item.page === 1 ? '' : `?page=${item.page}`}`}
              {...item}
            />
          )}
        />)}
    </Stack>
  );
}

export default NFTGrid