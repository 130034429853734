import React from 'react';
import { Link, useParams } from 'react-router-dom'
import { Container, List, ListItem, ListItemButton, ListItemText, ListSubheader, Typography } from "@mui/material"
import { useSelector, AppState } from "../store"
import SearchContractDialog from '../components/SearchContractDialog';
import {RecentContracts, ProviderKey} from '../utils'

function ChainView() {
  const params = useParams()
  const showSearch = useSelector<AppState, boolean>((state) => state.ui.showSearch)

  const isChain = (key: string) => {
    let c = ProviderKey.bsc === params.chain ? ProviderKey.bsc : ProviderKey.eth
    return key === c
  }

  return (
    <Container sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
      <List>
        <ListSubheader component="div">
        Recent Contracts
        </ListSubheader>
        {RecentContracts.map((item) => isChain(item.key) &&(
          <ListItem key={item.address} disablePadding>
            <ListItemButton component={Link} to={`/${params.chain}/${item.address}`}>
              <ListItemText primary={item.type} secondary={
                <Typography variant="subtitle2" noWrap={true}>{item.address}</Typography>
                } />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <SearchContractDialog open={showSearch} chain={params.chain} />
    </Container>
  )
}

export default ChainView